import { default as ai_45form_45builderlMrUMGIcEfMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editRAWaCmLCydMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index0dcxn9UIOiMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexPGsR883sozMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexzTGVldI31nMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharethmevynQP2Meta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as stats18xIf3Y6arMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsk1HdiKf76ZMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showPwmxdnUUGiMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guesttXvR3jWkS3Meta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexgebOStXhpkMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeBc9vfocTqGMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/home.vue?macro=true";
import { default as index4JouHOeh3rMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/index.vue?macro=true";
import { default as loginDCfM4hrLF8Meta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/login.vue?macro=true";
import { default as callbackLWFYlTCTpjMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as emailCJnyVpvw89Meta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93wEwHsIKtvAMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingqHoATgC0RpMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policy96sbeyJCUIMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as register8AvBj5P1LcMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokensG4MZuda7vtMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountXR2KLvF6qDMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as adminb7TZXQq5GhMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingHSy8ekl0m1Meta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionsIWBTPEjnvVMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as indexvhuRk1NWMkMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordMGNCmv34UMMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profileB2iQMpyYXtMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspaceOV7Q8JQUr8Meta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsqbd4S7nKRBMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/settings.vue?macro=true";
import { default as error7UtO0HrinsMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as successD06jfMoXQSMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93Q6xb2Q6oSxMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexzM0aa99KGpMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93KNKVTMyuZgMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesXQcYev0yPXMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93T1G3ZrY3H1Meta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsICmai6pAixMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsFDY1Sb6yUTMeta } from "/codebuild/output/src2654950693/src/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderlMrUMGIcEfMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/ai-form-builder.vue")
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editRAWaCmLCydMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/edit.vue")
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/index.vue")
  },
  {
    name: showPwmxdnUUGiMeta?.name,
    path: "/forms/:slug()/show",
    meta: showPwmxdnUUGiMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show.vue"),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    meta: indexPGsR883sozMeta || {},
    redirect: indexPGsR883sozMeta?.redirect,
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/index.vue")
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexzTGVldI31nMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue")
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: sharethmevynQP2Meta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/share.vue")
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: stats18xIf3Y6arMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/stats.vue")
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionsk1HdiKf76ZMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue")
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guesttXvR3jWkS3Meta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/create/guest.vue")
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexgebOStXhpkMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/forms/create/index.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: homeBc9vfocTqGMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginDCfM4hrLF8Meta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/login.vue")
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callbackLWFYlTCTpjMeta || {},
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/oauth/callback.vue")
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailCJnyVpvw89Meta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/password/email.vue")
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93wEwHsIKtvAMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/password/reset/[token].vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingqHoATgC0RpMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/pricing.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policy96sbeyJCUIMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register8AvBj5P1LcMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/register.vue")
  },
  {
    name: settingsqbd4S7nKRBMeta?.name,
    path: "/settings",
    meta: settingsqbd4S7nKRBMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings.vue"),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/access-tokens.vue")
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountXR2KLvF6qDMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/account.vue")
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminb7TZXQq5GhMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/admin.vue")
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingHSy8ekl0m1Meta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/billing.vue")
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsIWBTPEjnvVMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/connections.vue")
  },
  {
    name: "settings",
    path: "",
    meta: indexvhuRk1NWMkMeta || {},
    redirect: indexvhuRk1NWMkMeta?.redirect,
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/index.vue")
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordMGNCmv34UMMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/password.vue")
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profileB2iQMpyYXtMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/profile.vue")
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspaceOV7Q8JQUr8Meta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/settings/workspace.vue")
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: error7UtO0HrinsMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/subscriptions/error.vue")
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successD06jfMoXQSMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/subscriptions/success.vue")
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/[slug].vue")
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/index.vue")
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/industries/[slug].vue")
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesXQcYev0yPXMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/my-templates.vue")
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/templates/types/[slug].vue")
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsICmai6pAixMeta || {},
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/terms-conditions.vue")
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/codebuild/output/src2654950693/src/OpnForm/client/pages/update-credentials.vue")
  }
]